import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { Home } from "./app/pages/home";
import { Ebalance } from "./app/pages/ebalance";


export function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/ebalance" element={<Ebalance />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;


