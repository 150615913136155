import graphic from "./../../../assets/graphic.svg";
import finance from "./../../../assets/finance.svg";
import users from "./../../../assets/users.svg";
import services from "./../../../assets/services.svg";
import logoWhite from "./../../../assets/logo-white.svg";
import { Link } from 'react-router-dom';
import "./style.css";
require('typeface-poppins')

export function Ebalance() {

    const title = "E-Balance";
    document.title = title;

    function handleSchedule() {
        return window.open("https://calendly.com/lobolabs/ebalance", "_blank");
    }

    function createAccount() {
        return window.open("https://ebalance.lobolabs.com.br/#/register", "_blank");
    }

    return (
        <>
            <div className="content-head">
                <div className="info">
                    <h1>Agora, manter suas contas em ordem <span><br></br>é mais fácil do que nunca.</span></h1>
                    <button className="button-create" onClick={() => createAccount()}>Crie sua conta grátis</button>
                </div>
                <div className="graphic">
                    <img src={graphic} />
                </div>
            </div>
            <div className="content-ebalance-about">
                <h2>o que é o E-Balance?</h2>
                <span>O <b>E-Balance</b> é um software em nuvem projetado para ajudar seus clientes a gerenciar e controlar suas finanças empresariais de forma mais simples. </span>
            </div>
            <div className="content-ebalance-utils">
                <div className="content-item">
                    <div className="content-icon"><img src={finance} /></div>
                    <div className="content-text"><h3>Gestão financeira</h3></div>
                </div>

                <div className="content-item">
                    <div className="content-icon"><img src={users} /></div>
                    <div className="content-text"><h3>Gestão de usuários</h3></div>
                </div>

                <div className="content-item">
                    <div className="content-icon"><img src={services} /></div>
                    <div className="content-text"><h3>Gestão de serviços</h3></div>
                </div>
            </div>
            <div className="content-ebalance-functions">
                <h2>Vantagens de usar o Ebalance</h2>
                <div className="content-function">
                    <div className="content-item-function">
                        <span><span className="material-icons">done</span> Gestão Financeira</span>
                        <span><span className="material-icons">done</span> Controle de vendas</span>
                        <span><span className="material-icons">done</span> Controle de Despesas</span>
                    </div>
                    <div className="content-item-function">
                        <span><span className="material-icons">done</span> Controle de Serviços</span>
                        <span><span className="material-icons">done</span> Controle de Clientes</span>
                        <span><span className="material-icons">done</span> Controle de Fornecedores</span>
                    </div>
                    <div className="content-item-function">
                        <span><span className="material-icons">done</span> Controle de Destinatários</span>
                        <span><span className="material-icons">done</span> Gestão de usuários</span>
                        <span><span className="material-icons">done</span> Gestão de Empresas</span>
                    </div>
                </div>
                <div className="content-ebalance-action">
                    <button className="appointment-button" onClick={() => handleSchedule()}>Agende uma demo com um especialista</button>
                </div>
            </div>
            <div className="footer-content">
                <div className="content-private">
                    <Link to="/ebalance">Política de Privacidade</Link>
                    <Link to="/ebalance">Termo de uso</Link>
                </div>
                <div className="content-info">
                    <img src={logoWhite} alt="E-Balance"></img>
                </div>
                <div className="content-contact">
                    <b>Contato</b>
                    <Link to="mailto:ebalance@lobolabs.com.br">ebalance@lobolabs.com.br</Link>
                </div>
            </div>
            <div className="footer-text">
                <span><b>© E-Balance</b> - Todos os direitos reservados 2023. - Powered by <Link to="/">Lobo<b>Labs</b></Link></span>
            </div>
        </>
    )
}